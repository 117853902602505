<template>
  <div class="bill_get">
    <div class="bill_plan">
      <el-row>
        <el-col :span="8">
          <div class="paln_icon tar" :class="step?'acitve':''">
            <span class="iconfont icon">1</span>
            <span class="text">选择订单</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="paln_icon tac">
            <span class="iconfont iconjiantou"></span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="paln_icon" :class="!step?'acitve':''">
            <span class="iconfont icon">2</span>
            <span class="text">确定发票信息和地址</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="bill_table_box" v-show="step">
      <div>
        <el-checkbox
          class="fr"
          v-model="set_info.is_all"
          :label="'全选：共 ' + bill.totalCount + ' 张订单/充值可申请发票'"
          border
        ></el-checkbox>
      </div>
      <div class="bill_table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="48"></el-table-column>
          <el-table-column prop="num" label="订单号/充值流水号"></el-table-column>
          <el-table-column prop="time" label="支付/充值时间"></el-table-column>
          <el-table-column prop="pay" label="实际支付金额"></el-table-column>
          <el-table-column prop="open" label="可开票金额" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="paging_arr clr">
          <div class="btn_left fl">
            <div class="btn dib">
              <el-button
                type="primary"
                v-if="tab_btn || set_info.is_all "
                @click="step=false"
              >申请开具发票</el-button>
              <el-button type="primary" class="btn_out" v-else>申请开具发票</el-button>
            </div>
            <div class="text dib mal28">
              待开票金额：
              <span>￥{{set_info.is_all ? bill.money :set_info.amount}}</span>
            </div>
            <p style="margin-top:15px">
              <el-tag type="primary" size="medium">提示：当前选中的多个订单系统会默认合并成一张发票</el-tag>
            </p>
          </div>
          <div class="btn_right fr">
            <el-pagination
              @size-change="e=> info.pageSize = e"
              @current-change="e=> info.pageIndex = e"
              :current-page.sync="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, prev, pager, next"
              :total="total_list"
            ></el-pagination>
          </div>
          <div class="hint_left fr">共找到{{total_list}}条内容</div>
        </div>
      </div>
    </div>
    <div class="bill_site" v-show="!step">
      <div class="bill_box">
        <div class="bill_hint">
          <span class="tag">注意</span>：请选择开票信息，并核实发票信息是否正确，如果不正确前往：
          <router-link to="bill?id=3">
            <el-link class="fwb" icon="el-icon-edit" type="danger">修改 / 新增发票</el-link>
          </router-link>
        </div>
        <div class="site_table">
          <SetBill ref="bill" />
        </div>
        <div class="bill_hint">
          <div class="bj">备注信息：</div>
        </div>
        <div class="remark_info">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6}"
            placeholder="请输入您的备注信息"
            v-model="set_info.remark"
          ></el-input>
        </div>
      </div>
      <div class="site_btn clr">
        <div class="btn fl">
          <el-button icon="el-icon-arrow-left" @click="step=true">上一步</el-button>
        </div>
        <div class="btn fl">
          <el-button type="primary" @click="open_bill">确认开票</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { moment_set } from "@/assets/js/validate";
import SetBill from "@/components/bill/set_bill";
export default {
  components: { SetBill },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      tab_btn: false,
      step: true,
      info: {
        pageIndex: 1,
        pageSize: 10
      },
      bill: {
        totalCount: 0
      },
      total_list: 0,
      // 选中的数组
      set_info: {
        is_all: false,
        out_trade_nos: [],
        amount: 0,
        remark: ""
      }
    };
  },
  watch: {
    info: {
      handler(v) {
        this.get_list();
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSelectionChange(e) {
      this.set_info.out_trade_nos = [];
      this.set_info.amount = 0;
      if (e.length !== 0) {
        e.forEach(i => {
          this.set_info.out_trade_nos.push(i.num);
          this.set_info.amount += i.pay;
        });
        this.tab_btn = true;
      } else {
        this.tab_btn = false;
      }
    },
    get_list() {
      this.tableData = [];
      this.$api.payments_get(this.info).then(res => {
        res.data.list.forEach(e => {
          this.tableData.push({
            num: e.out_trade_no,
            time: moment_set(e.payTime),
            pay: e.payment,
            open: e.payment
          });
        });
        this.bill.totalCount = res.data.totalCount;
        this.bill.money = res.data.allAmount;
        this.total_list = res.data.totalCount;
      });
    },
    // 立即开票
    open_bill() {
      let id = this.$refs.bill.get_id();
      if (!id) return this.$message.warning("请选择开票信息");
      this.set_info.invoiceInfoId = id;
      this.$api.invoices_post(this.set_info).then(res => {
        if (res.errcode === 0) {
          this.$message.success("开票申请成功，请等待审核结果~");
          this.get_list();
          this.step = true;
        }
      });
    }
  }
};
</script>
<style lang="scss">
$yellow: rgb(242, 200, 17);
.bill_get {
  .bill_plan {
    margin-bottom: 65px;
    .paln_icon {
      color: rgb(120, 120, 120);
      line-height: 38px;
      vertical-align: middle;
      font-weight: bold;
      .text {
        font-size: 20px;
        vertical-align: middle;
        padding-left: 10px;
      }

      .iconfont {
        display: inline-block;
        font-size: 20px;
        text-align: center;
        border-radius: 50%;
        vertical-align: middle;
      }
      .iconjiantou {
        font-size: 28px;
        text-align: center;
      }
      .icon {
        height: 38px;
        line-height: 38px;
        width: 38px;
        color: rgb(120, 120, 120);
        border: 1px solid rgb(120, 120, 120);
      }
    }
    .acitve {
      .iconfont {
        background: $yellow;
        border: 1px solid $yellow;
        color: #000000;
      }
      .text {
        color: #000000;
      }
    }
  }
  .bill_table {
    .paging_arr {
      margin-top: 40px;
      .btn_left {
        .btn_out {
          background: rgb(222, 222, 222);
          border: 1px solid rgb(222, 222, 222);
        }
        .el-button {
          font-weight: bold;
        }
        .text {
          font-size: 16px;
          span {
            color: rgb(255, 0, 0);
            font-weight: bold;
          }
        }
      }
      .hint_left {
        font-size: 12px;
        line-height: 33px;
      }
    }

    .el-button,
    .el-table thead {
      color: #000000;
    }
    .el-table__body {
      border-collapse: separate;
      border-spacing: 0 4px;
      background: #eee;
    }
    .el-table th {
      background: #eee;
    }

    .el-table .hover-row {
      background: rgba(242, 200, 17, 0.5);
    }
    .el-table .el-table__row:hover {
      background: rgba(242, 200, 17, 0.7);
    }
    // 鼠标悬浮
    .el-table--enable-row-hover .el-table__body tr:hover {
      background: rgb(242, 200, 17);
      border: 1px solid #313463;
      box-shadow: 0 0 1px 1px rgb(242, 200, 17),
        inset 0 0 10px 5px rgba(242, 200, 17, 0.2);
    }
    .el-pager li.active {
      color: #000;
      cursor: default;
      background: #f2c811;
    }
  }
  .bill_site {
    .site_btn {
      padding: 20px;
      .btn {
        margin-right: 30px;
      }
    }
    .bill_box {
      padding: 20px;

      .remark_info {
        width: 50%;
        margin-top: 10px;
      }
      .el-table__body {
        border-collapse: separate;
        border-spacing: 0 4px;
        background: #eee;
      }
      .bill_hint {
        font-size: 14px;
        zoom: 1;
        .tag {
          color: red;
        }
        .bj {
          margin-top: 30px;
        }
      }
      .site_table {
        .has-gutter {
        //   display: none;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
 