var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bill_info"},[_c('div',[_c('el-form',{ref:"ruleForm",staticClass:"ruleForm",attrs:{"model":_vm.ruleForm,"label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"发票抬头：","prop":"invoiceTitle","rules":[{ required: true, message: '请输入发票抬头' }]}},[_c('el-input',{model:{value:(_vm.ruleForm.invoiceTitle),callback:function ($$v) {_vm.$set(_vm.ruleForm, "invoiceTitle", $$v)},expression:"ruleForm.invoiceTitle"}})],1),_c('el-form-item',{attrs:{"label":"开具类型：","prop":"region","rules":[{ required: true, message: '请选择开具类型' }]}},[_c('el-select',{attrs:{"placeholder":"请选择开具类型："},model:{value:(_vm.ruleForm.region),callback:function ($$v) {_vm.$set(_vm.ruleForm, "region", $$v)},expression:"ruleForm.region"}},[_c('el-option',{attrs:{"label":"企业","value":1}}),_c('el-option',{attrs:{"label":"个人","value":0}})],1)],1),_c('el-form-item',{attrs:{"label":"发票类型：","prop":"type","rules":[{ required: true, message: '请选择发票类型' }]}},[_c('el-select',{attrs:{"placeholder":"请选择发票类型"},model:{value:(_vm.ruleForm.type),callback:function ($$v) {_vm.$set(_vm.ruleForm, "type", $$v)},expression:"ruleForm.type"}},[_c('el-option',{attrs:{"label":"电子普通发票","value":0}})],1)],1),(_vm.ruleForm.region !== 0)?_c('el-form-item',{attrs:{"label":"税务登记号：","prop":"num","rules":[{ required: true, message: '税务登记号不能为空' }]}},[_c('el-input',{model:{value:(_vm.ruleForm.num),callback:function ($$v) {_vm.$set(_vm.ruleForm, "num", $$v)},expression:"ruleForm.num"}})],1):_vm._e(),(_vm.ruleForm.type === 1)?_c('div',[_c('el-form-item',{attrs:{"label":"注册地址：","prop":"registAddress","rules":[
            { required: true, message: '请输入注册地址', trigger: 'blur' },
          ]}},[_c('el-input',{model:{value:(_vm.ruleForm.registAddress),callback:function ($$v) {_vm.$set(_vm.ruleForm, "registAddress", $$v)},expression:"ruleForm.registAddress"}})],1),_c('el-form-item',{attrs:{"label":"注册电话：","prop":"registPhone","rules":[{ required: true, message: '请输入注册电话' }]}},[_c('el-input',{model:{value:(_vm.ruleForm.registPhone),callback:function ($$v) {_vm.$set(_vm.ruleForm, "registPhone", $$v)},expression:"ruleForm.registPhone"}})],1),_c('el-form-item',{attrs:{"label":"开户银行：","prop":"bank","rules":[{ required: true, message: '请输入开户银行' }]}},[_c('el-input',{model:{value:(_vm.ruleForm.bank),callback:function ($$v) {_vm.$set(_vm.ruleForm, "bank", $$v)},expression:"ruleForm.bank"}})],1),_c('el-form-item',{attrs:{"label":"银行账户：","prop":"bankAccount","rules":[{ required: true, message: '请输入银行账户' }]}},[_c('el-input',{model:{value:(_vm.ruleForm.bankAccount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "bankAccount", $$v)},expression:"ruleForm.bankAccount"}})],1),_c('el-form-item',{attrs:{"label":"收票人地址：","prop":"address","rules":[{ required: true, message: '请输入银行账户' }]}},[_c('el-input',{model:{value:(_vm.ruleForm.address),callback:function ($$v) {_vm.$set(_vm.ruleForm, "address", $$v)},expression:"ruleForm.address"}})],1)],1):_vm._e(),(_vm.ruleForm.type !== 1)?_c('el-form-item',{attrs:{"label":"递送邮箱：","prop":"email","rules":[
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ]}},[_c('el-input',{model:{value:(_vm.ruleForm.email),callback:function ($$v) {_vm.$set(_vm.ruleForm, "email", $$v)},expression:"ruleForm.email"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"联系人：","prop":"name","rules":[{ required: true, message: '联系人不能为空' }]}},[_c('el-input',{model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('el-form-item',{attrs:{"label":"联系人电话：","prop":"tell","rules":[{ required: true, message: '联系人电话不能为空' }]}},[_c('el-input',{model:{value:(_vm.ruleForm.tell),callback:function ($$v) {_vm.$set(_vm.ruleForm, "tell", $$v)},expression:"ruleForm.tell"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("保存修改")]),(this.ruleForm.id)?_c('el-button',{on:{"click":_vm.onDelet}},[_vm._v("删除发票")]):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }