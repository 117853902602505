<template>
  <div class="address_wrap">
    <div class="flex align-center margin-bottom-sm">
      <span class="flex-sub text-bold">{{ status ? "开票信息列表" : "" }}</span>
      <el-button
        class="margin-right"
        icon="el-icon-plus"
        type="primary"
        @click="set()"
        >新增发票</el-button
      >
    </div>
    <el-table
      :data="list"
      tooltip-effect="dark"
      ref="addrTable"
      highlight-current-row
      @current-change="handleCurrentChange"
    >
      <el-table-column
        prop="invoiceTitle"
        label="抬头"
        width="180"
      ></el-table-column>
      <el-table-column prop="contactName" label="联系人"></el-table-column>
      <el-table-column prop="contactMobile" label="电话"></el-table-column>
      <el-table-column prop="taxNo" label="税务登记号"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
      <el-table-column prop="type" label="开票类型" :formatter="formatType"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <a class="text-blue" @click="set(scope.row)">
            <i class="el-icon-edit"></i>
            编辑
          </a>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="发票信息"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="visible_open"
      width="60%"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <BillInfo v-if="visible_open" @edit="onEdit" :ruleForm="form" />
    </el-dialog>
  </div>
</template>
<script>
import BillInfo from "@/components/bill/bill_info";
export default {
  components: { BillInfo },
  props: ["status"],
  data() {
    return {
      list: [],
      visible_open: false,
      form: {},
      id: "",
      reset: false,
    };
  },
  created() {
    this.get_list();
  },
  methods: {
    formatType(row, column) {
      switch (row.type) {
        case 0:
          return "普通电子发票";
        case 1:
          return "增值税专用发票（纸质）";
        default:
          return "未知类型";
      }
    },
    setCurrent(row) {
      this.$refs.addrTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    get_id() {
      this.id = this.currentRow?.id || "";
      return this.id;
    },
    set(item) {
      this.visible_open = true;
      this.form = item
        ? {
            invoiceTitle: item.invoiceTitle,
            name: item.contactName,
            region: item.issueType,
            email: item.email,
            tell: item.contactMobile,
            num: item.taxNo,
            type: item.type,
            bank: item.bank,
            bankAccount: item.bankAccount,
            registAddress: item.registAddress,
            registPhone: item.registPhone,
            address: item.address,
            id: item.id,
          }
        : {};
    },
    onEdit() {
      this.visible_open = false;
      this.get_list();
    },
    async get_list() {
      let data = await this.$api.allinvoiceinfos();
      this.list = data.data.map((i) => {
        i.id = i._id;
        return i;
      });
    },
  },
};
</script>
<style lang="scss">
.address_wrap {
  .radio {
    margin: 5px 0;
    padding: 5px 0;
    font-size: 14px;
  }
  .btn {
    float: right;
    margin-right: 20px;
  }

  .el-table thead {
    color: #000000;
  }
  .el-table__body {
    border-collapse: separate;
    border-spacing: 0 4px;
    background: #eee;
  }
  .el-table th {
    background: #eee;
    height: 20px;
  }

  .el-table .hover-row {
    background: rgba(242, 200, 17, 0.5);
  }
  .el-table .el-table__row:hover {
    background: rgba(242, 200, 17, 0.7);
  }
  // 鼠标悬浮
  .el-table--enable-row-hover .el-table__body tr:hover {
    background: rgb(242, 200, 17);
    border: 1px solid #313463;
    box-shadow: 0 0 1px 1px rgb(242, 200, 17),
      inset 0 0 10px 5px rgba(242, 200, 17, 0.2);
  }
  .el-pager li.active {
    color: #000;
    cursor: default;
    background: #f2c811;
  }
}
</style>
