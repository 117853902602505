<template>
  <div class="bill_box">
    <div class="bill_tabs">
      <el-row>
        <el-col :span="8">
          <el-button @click="tabs(1)" :type="index === 1 ? 'primary' : ''"
            >发票列表</el-button
          >
        </el-col>
        <el-col :span="8">
          <el-button :type="index === 2 ? 'primary' : ''" @click="tabs(2)"
            >发票索取</el-button
          >
        </el-col>
        <el-col :span="8">
          <el-button :type="index === 3 ? 'primary' : ''" @click="tabs(3)"
            >开票信息</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="bill_form">
      <bill-list v-if="index === 1"></bill-list>
      <bill-get v-else-if="index === 2" @open="open"></bill-get>
      <SetBill v-else-if="index === 3" :status="1" />
    </div>
  </div>
</template>

<script>
import { BillGet, BillList } from "@/components/bill";
import SetBill from "@/components/bill/set_bill";
export default {
  components: {
    BillGet,
    BillList,
    SetBill,
  },
  data() {
    return {
      index: 1,
    };
  },
  mounted() {
    let id = parseInt(this.$route.query.id) || 1;
    this.tabs(id);
  },
  methods: {
    tabs(e) {
      this.index = e;
      switch (e) {
        case 1:
          this.MtaH5.clickStat("bill", { list: "true" });
          break;
        case 2:
          this.MtaH5.clickStat("bill", { get: "true" });
          break;
        case 3:
          this.MtaH5.clickStat("bill", { info: "true" });
          break;
        default:
          break;
      }
    },
    open(e) {
      this.tabs(e);
      this.$store.commit("set_bill", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.bill_box {
  .bill_tabs {
    .el-button {
      width: 100%;
      padding: 26px 0;
      color: #000000;
      font-weight: bold;
      font-size: 16px;
      border-radius: 0;
    }
  }
  .bill_form {
    margin-top: 61px;
  }
}
</style>
