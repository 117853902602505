<template>
  <div class="bill_list_box">
    <div class="set_form flex">
      <div class="select_right">
        <div class="block">
          <span class="demonstration">开票时间范围：</span>
          <el-date-picker
            v-model="time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickertime"
          ></el-date-picker>
        </div>
      </div>
      <div class="select_left">
        <span class="demonstration">发票状态：</span>
        <el-select v-model="info.status" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="form_input">
        <el-input
          placeholder="订单号/充值流水号"
          v-model="info.keyword"
          clearable
        ></el-input>
      </div>
    </div>
    <div class="bill_table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="time"
          label="申请时间"
          width="180"
        ></el-table-column>
        <el-table-column prop="num" label="发票抬头"></el-table-column>
        <el-table-column
          prop="money"
          label="开票金额"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="发票性质"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="state"
          label="发票状态"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="manager_remark"
          label="开票信息"
        ></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
      <div class="paging_arr clr">
        <div class="btn_right fr">
          <el-pagination
            @size-change="(e) => (info.pageSize = e)"
            :current-page.sync="info.pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next"
            :total="page_list"
          ></el-pagination>
        </div>
        <div class="hint_left fr">共找到{{ page_list }}条内容</div>
      </div>
    </div>
  </div>
</template>

<script>
import { moment_date, moment_set } from "@/assets/js/validate";
export default {
  data() {
    return {
      tag: "",
      options: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "申请开票中",
        },
        {
          value: 2,
          label: "已开票",
        },
        {
          value: 3,
          label: "已关闭",
        },
      ],
      tab_state: "",
      pickertime: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      time: "",
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      info: {
        keyword: "",
        status: 0,
        start: "",
        end: "",
        pageIndex: 1,
        pageSize: 10,
      },
      page_list: 0,
    };
  },
  watch: {
    info: {
      handler(v) {
        this.get_table();
      },
      deep: true,
    },
    time(e) {
      if (e !== null) {
        this.info.start = moment_date(e[0]);
        this.info.end = moment_date(e[1]);
      } else {
        this.info.start = "";
        this.info.end = "";
      }
    },
  },
  mounted() {
    this.get_table();
  },
  methods: {
    get_table() {
      this.tableData = [];
      this.$api.invoices(this.info).then((res) => {
        res.data.list.forEach((e, i) => {
          this.tableData.push({
            time: moment_set(e.createAt),
            num: e.invoiceTitle || "",
            money: e.invoiceAmount || "",
            type: e.type === 0 ? "电子普通发票" : "增值税发票",
            state:
              e.status === 1
                ? "申请开票中"
                : e.status === 2
                ? "已开票"
                : "开票信息有误",
            remark: e.remark || "",
            manager_remark: e.manager_remark,
          });
        });
        this.page_list = res.data.totalCount;
      });
    },
  },
};
</script>

<style lang="scss"  >
.bill_list_box {
  .set_form {
    margin-bottom: 50px;
    .demonstration {
      font-size: 14px;
    }
    .select_right {
      margin-right: 54px;
      .block {
        .el-range-editor.el-input__inner {
          width: 288px;
        }
      }
    }
    .select_left {
      margin-right: 11px;
      .el-select {
        width: 125px;
      }
    }
    .form_input {
      width: 284px;
    }
    .get_form {
      margin-left: 25px;
      .btn {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        /* 水平居中*/
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-pack: center; /* IE 10 */
        -webkit-justify-content: center;
        -moz-justify-content: center;
        justify-content: center; /* IE 11+,Firefox 22+,Chrome 29+,Opera 12.1*/
        /* 垂直居中 */
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-align: center; /* IE 10 */
        -webkit-align-items: center;
        -moz-align-items: center;
        height: 40px;
      }
      .el-button {
        padding: 8.5px 21px;
        background: rgb(242, 200, 17);
        color: #000000;
      }
    }
  }
  .bill_table {
    .paging_arr {
      margin-top: 40px;
      .btn_right {
      }
      .hint_left {
        font-size: 12px;
        line-height: 33px;
      }
    }

    .el-button,
    .el-table thead {
      color: #000000;
    }
    .el-table__body {
      border-collapse: separate;
      border-spacing: 0 4px;
      background: #eee;
    }
    .el-table th {
      background: #eee;
    }

    .el-table .hover-row {
      background: rgba(242, 200, 17, 0.5);
    }
    .el-table .el-table__row:hover {
      background: rgba(242, 200, 17, 0.7);
    }
    // 鼠标悬浮
    .el-table--enable-row-hover .el-table__body tr:hover {
      background: rgb(242, 200, 17);
      border: 1px solid #313463;
      box-shadow: 0 0 1px 1px rgb(242, 200, 17),
        inset 0 0 10px 5px rgba(242, 200, 17, 0.2);
    }
    .el-pager li.active {
      color: #000;
      cursor: default;
      background: #f2c811;
    }
  }
}
</style>
