<template>
  <div class="bill_info">
    <div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="150px"
        class="ruleForm"
      >
        <el-form-item
          label="发票抬头："
          prop="invoiceTitle"
          :rules="[{ required: true, message: '请输入发票抬头' }]"
        >
          <el-input v-model="ruleForm.invoiceTitle"></el-input>
        </el-form-item>
        <el-form-item
          label="开具类型："
          prop="region"
          :rules="[{ required: true, message: '请选择开具类型' }]"
        >
          <el-select v-model="ruleForm.region" placeholder="请选择开具类型：">
            <el-option label="企业" :value="1"></el-option>
            <el-option label="个人" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="发票类型："
          prop="type"
          :rules="[{ required: true, message: '请选择发票类型' }]"
        >
          <el-select v-model="ruleForm.type" placeholder="请选择发票类型">
            <el-option label="电子普通发票" :value="0"></el-option>
            <!-- <el-option
              label="增值税专用发票"
              :value="1"
              v-if="ruleForm.region === 0 ? ruleForm.type = 0  :  true"
            ></el-option>-->
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.region !== 0"
          label="税务登记号："
          prop="num"
          :rules="[{ required: true, message: '税务登记号不能为空' }]"
        >
          <el-input v-model="ruleForm.num"></el-input>
        </el-form-item>
        <!-- 增值税发票 -->
        <div v-if="ruleForm.type === 1">
          <el-form-item
            label="注册地址："
            prop="registAddress"
            :rules="[
              { required: true, message: '请输入注册地址', trigger: 'blur' },
            ]"
          >
            <el-input v-model="ruleForm.registAddress"></el-input>
          </el-form-item>
          <el-form-item
            label="注册电话："
            prop="registPhone"
            :rules="[{ required: true, message: '请输入注册电话' }]"
          >
            <el-input v-model="ruleForm.registPhone"></el-input>
          </el-form-item>
          <el-form-item
            label="开户银行："
            prop="bank"
            :rules="[{ required: true, message: '请输入开户银行' }]"
          >
            <el-input v-model="ruleForm.bank"></el-input>
          </el-form-item>
          <el-form-item
            label="银行账户："
            prop="bankAccount"
            :rules="[{ required: true, message: '请输入银行账户' }]"
          >
            <el-input v-model="ruleForm.bankAccount"></el-input>
          </el-form-item>
          <el-form-item
            label="收票人地址："
            prop="address"
            :rules="[{ required: true, message: '请输入银行账户' }]"
          >
            <el-input v-model="ruleForm.address"></el-input>
          </el-form-item>
        </div>
        <el-form-item
          v-if="ruleForm.type !== 1"
          label="递送邮箱："
          prop="email"
          :rules="[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            {
              type: 'email',
              message: '请输入正确的邮箱地址',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item
          label="联系人："
          prop="name"
          :rules="[{ required: true, message: '联系人不能为空' }]"
        >
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item
          label="联系人电话："
          prop="tell"
          :rules="[{ required: true, message: '联系人电话不能为空' }]"
        >
          <el-input v-model="ruleForm.tell"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">保存修改</el-button>
          <el-button @click="onDelet" v-if="this.ruleForm.id"
            >删除发票</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  props: ["ruleForm"],
  data() {
    this.ruleForm.type = 0; //固定电子发票类型
    return {};
  },
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let info = {
            issueType: this.ruleForm.region,
            type: this.ruleForm.type,
            invoiceTitle: this.ruleForm.invoiceTitle,
            taxNo: this.ruleForm.num,
            contactName: this.ruleForm.name,
            contactMobile: this.ruleForm.tell,
            email: this.ruleForm.email,
            bank: this.ruleForm.bank,
            bankAccount: this.ruleForm.bankAccount,
            registAddress: this.ruleForm.registAddress,
            registPhone: this.ruleForm.registPhone,
            address: this.ruleForm.address,
            id: this.ruleForm.id || "",
          };
          if (this.ruleForm.id) {
            let res = await this.$api.post_invoiceinfos(info);
            if (res.errcode === 0) {
              this.$message.success("发票修改成功~");
              this.$emit("edit");
            }
          } else {
            let res = await this.$api.invoiceinfos(info);
            if (res.errcode === 0) {
              this.$message.success("发票信息添加成功~");
              this.$emit("edit");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onDelet() {
      this.$api.delete_invoiceinfos(this.ruleForm.id).then((res) => {
        if (res.errcode === 0) {
          this.$message.success("删除成功");
          this.$emit("edit");
        }
      });
    },
  },
};
</script>
  <style lang="scss" >
.bill_info {
  .el-form-item {
    width: 95%;
    margin-bottom: 15px;
    .el-form-item__error {
      padding-top: 1px;
    }
  }
  .title {
    color: rgb(120, 120, 120);
    padding-left: 30px;
    font-size: 16px;
  }
  .set_info {
    color: #000;
    font-weight: bold;
    .el-button {
      font-weight: bold;
    }
  }
  .info {
    font-size: 16px;
    .lock_false {
      .input,
      el-select {
        width: 40%;
      }
    }
  }
  .paging_arr {
    margin-top: 40px;
    .hint_left {
      font-size: 12px;
      line-height: 33px;
    }
  }

  .el-button,
  .el-table thead {
    color: #000000;
  }
  .el-table__body {
    border-collapse: separate;
    border-spacing: 0 4px;
    background: #eee;
  }
  .el-table th {
    background: #eee;
  }

  .el-table .hover-row {
    background: rgba(242, 200, 17, 0.5);
  }
  .el-table .el-table__row:hover {
    background: rgba(242, 200, 17, 0.7);
  }
  // 鼠标悬浮
  .el-table--enable-row-hover .el-table__body tr:hover {
    background: rgb(242, 200, 17);
    border: 1px solid #313463;
    box-shadow: 0 0 1px 1px rgb(242, 200, 17),
      inset 0 0 10px 5px rgba(242, 200, 17, 0.2);
  }
  .el-pager li.active {
    color: #000;
    cursor: default;
    background: #f2c811;
  }
}
</style>
